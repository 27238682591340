import * as React from "react"
import screen1 from "../../images/screen1.png"
import AddToSlackButton from "../buttons/AddToSlack"

export default function Hero() {
  return (
    <div className="lg:relative bg-gray-50 pt-8 overflow-hidden sm:pt-4 lg:pt-8">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <p className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Stay on top of your{" "}
            <span class="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-indigo-600">
              cloud spending
            </span>{" "}
            within Slack
          </p>
          <p className="mt-5 max-w-xl mx-auto font-medium text-2xl sm:text-2xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-blue-500">
            How much do you spend on cloud?
          </p>
          <p className="max-w-xl mx-auto font-medium text-2xl sm:text-2xl text-gray-600">
            {" "}
            With daily reports in Slack, you finally know.
          </p>
          <p className="mt-3 max-w-xl mx-auto text-md sm:text-md text-gray-500">
            Available for AWS accounts. GCP and Azure coming soon.
          </p>
          <div className="mt-6">
            <AddToSlackButton />
          </div>
        </div>
        <div className="mt-8 -mb-10 sm:-mb-24 lg:-mb-38">
          <img
            className="rounded-lg shadow-2xl ring-1 ring-black ring-opacity-5"
            src={screen1}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
