import React from "react"
import AwsLogo from "../../images/AWS.svg"
import AzureWhiteLogo from "../../images/AzureWhite.svg"
import GcpLogo from "../../images/GCP.svg"
const companies = [
  { name: "Microsoft Azure", logo: AzureWhiteLogo },
  { name: "Amazon Web Services", logo: AwsLogo },
  { name: "Google Cloud Platform", logo: GcpLogo },
]

export default function Companies() {
  return (
    <div className="bg-indigo-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-3">
          {companies.map(item => {
            return (
              <div className="col-span-1 flex justify-center">
                <img className="h-12" src={item.logo} alt={item.name} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
