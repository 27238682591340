import React from "react"
import {
  CloudIcon,
  SparklesIcon,
  UserGroupIcon,
} from "@heroicons/react/outline"
import heroImage1 from "../../images/hero-image-1.png"
import heroImage2 from "../../images/hero-image-2.png"
import heroImage3 from "../../images/hero-image-3.png"

export default function Features() {
  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="h-[30rem]  lg:mr-96 lg:ml-40  lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-20 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-500">
                  <CloudIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Keep your cloud costs under control
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Cloud spending remains a high expenditure for software
                  engineering teams and individuals. Daily monitoring in Slack
                  can help you understand how much you spend and review your
                  workloads when there is an unexpected spike.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0">
            <div className="p-4 sm:-mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={heroImage1}
                alt="Cloud Spend Bot User Interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:ml-96 lg:mr-40  lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-40 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-500">
                  <SparklesIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Automated spike alerts and monitoring
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Immediately understand when you are spending more than usual
                  across your cloud accounts with intelligent spike alerts and
                  monitoring, and take actions before it's too late.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 sm:-ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={heroImage2}
                alt="Slack with Cloud Spend Bot"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 relative">
        <div className="h-[30rem] lg:mr-96 lg:ml-40 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-20 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-500">
                  <UserGroupIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Collaborate with your team
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Work with your team - automatically synced with your Slack
                  workspace. You can manage user permissions to keep your data
                  safe.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0">
            <div className="p-4 sm:-mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={heroImage3}
                alt="Cloud Spend Bot User Interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
